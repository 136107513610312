<template>
  
    <div class="content">
      <div class="row">
        <div class="col-12">
          <div class="card mb-3 btn-reveal-trigger">
            <div class="card-header position-relative min-vh-25 mb-8">
              <div class="cover-image">
                <div class="bg-holder rounded-soft rounded-bottom-0" style="background-image:url(/img/generic/4.jpg);">
                </div>
                <!--/.bg-holder-->

                <input class="d-none" id="upload-cover-image" type="file">
                <label class="cover-image-file-input" for="upload-cover-image">
                  <span class="fas fa-camera mr-2"></span>
                  <span>Изменить обложку</span>
                </label>
              </div>
              <div class="avatar avatar-5xl avatar-profile shadow-sm img-thumbnail rounded-circle">
                <div class="h-100 w-100 rounded-circle overflow-hidden position-relative"> 
                  <img src="@/assets/img/team/avatar-placeholder.png" width="200" alt="">
                  <input class="d-none" id="profile-image" type="file">
                  <label class="mb-0 overlay-icon d-flex flex-center" for="profile-image">
                    <span class="bg-holder overlay overlay-0"></span>
                    <span class="z-index-1 text-white text-center fs--1">
                      <span class="fas fa-camera"></span>
                      <span class="d-block">Обновить</span>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-lg-8 pr-lg-2">
          <div class="card mb-3">
            <div class="card-header">
              <h5 class="mb-0">Настройки профиля</h5>
            </div>
            <div class="card-body bg-light">
              <form>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="first-name">Имя</label>
                      <input 
                        class="form-control" 
                        id="first-name" 
                        type="text"
                        @change="userInfoChanged = true" 
                        :disabled="loading"
                        v-model="userInfo.firstName">
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="last-name">Фамилия</label>
                      <input 
                        class="form-control" 
                        id="last-name" 
                        type="text"
                        @change="userInfoChanged = true"
                        :disabled="loading" 
                        v-model="userInfo.lastName">
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="email1">Email</label>
                      <input 
                        class="form-control" 
                        id="email1" 
                        type="text" 
                        disabled 
                        :value="userInfo.email || ''">
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="phone">Телефон</label>
                      <input 
                        class="form-control" 
                        id="phone" 
                        type="text" 
                        :disabled="loading"
                        @change="userInfoChanged = true"
                        v-model="userInfo.phoneNumber">
                    </div>
                  </div>
                  <div class="col-12 d-flex justify-content-end">
                    <button 
                      class="btn btn-primary" 
                      type="submit"
                      :disabled="!userInfoChanged"
                      @click.prevent="updateUserInfo">
                      <span 
                        v-if="loading"
                        class="spinner-border spinner-border-sm" 
                        role="status" 
                        aria-hidden="true">
                      </span>
                      Обновить данные 
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-lg-4 pl-lg-2">
          <div class="sticky-top sticky-sidebar">
            <div class="card mb-3">
              <div class="card-header">
                <h5 class="mb-0">Настройки подписки</h5>
              </div>
              <div class="card-body bg-light">
                <h5>Подписка</h5>
                <p class="fs-0">
                  <strong>{{userInfo.subscriptionType || ''}}</strong>
                  {{subscription}}</p>
                <router-link 
                  class="btn btn-falcon-default btn-sm" 
                  to="/user/plans">Изменить подписку
                </router-link>
                <router-link 
                  class="btn btn-falcon-success btn-sm ml-2 disabled" 
                  to="!#">Продлить
                </router-link>
              </div>
              <!-- <div class="card-body bg-light border-top">
                <h5>Платежи</h5>
                <p class="fs-0">У вас нет ни одного платежа</p>
                <a class="btn btn-falcon-default btn-sm" href="#!">Добавить платеж</a>
              </div> -->
            </div>
            <div class="card mb-3">
              <div class="card-header">
                <h5 class="mb-0">Изменить пароль</h5>
              </div>
              <div class="card-body bg-light">
                <form>
                  <div class="form-group">
                    <label for="old-password">Текущий пароль</label>
                    <input 
                      class="form-control" 
                      id="old-password" 
                      type="password"
                      :disabled="passChangeLoad"
                      v-model.trim="$v.oldPass.$model" 
                      :class="{
                        'is-invalid': $v.oldPass.$error,
                      }" 
                    />
                  </div>
                  <div class="form-group">
                    <label for="new-password">Новый пароль</label>
                    <input 
                      class="form-control" 
                      id="new-password" 
                      type="password"
                      :disabled="passChangeLoad"
                      v-model.trim="$v.newPass.$model" 
                      @change="$v.newPass2.$model = ''"
                      :class="{
                        'is-invalid': $v.newPass.$error,
                        'is-valid': ($v.newPass.$dirty && !$v.newPass.$error)
                      }" 
                    />
                  </div>
                  <div class="form-group">
                    <label for="confirm-password">Подтвердить новый пароль</label>
                    <input 
                      class="form-control" 
                      id="confirm-password" 
                      type="password"
                      :disabled="passChangeLoad"
                      v-model.trim="$v.newPass2.$model"
                      :class="{
                        'is-invalid': $v.newPass2.$error,
                        'is-valid': $v.newPass2.$dirty && !$v.newPass2.$error && $v.newPass2.$model != ''
                      }"
                    />
                  </div>
                  <button 
                    class="btn btn-primary btn-block" 
                    type="submit"
                    :disabled="!oldPass"
                    @click.prevent="changePassword">
                    <span 
                      v-if="passChangeLoad"
                      class="spinner-border spinner-border-sm" 
                      role="status" 
                      aria-hidden="true">
                    </span>
                    Сменить пароль
                  </button>
                </form>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h5 class="mb-0">Зона особого внимания!</h5>
              </div>
              <div class="card-body bg-light">
                <h5 class="fs-0">Удалить аккаунт</h5>
                <p class="fs--1">Если Вы удалите аккаут все данные будут стерты безвозвратно! Подумайте, прежде чем это сделать...</p>
                <a class="btn btn-falcon-danger d-block" href="#!">Деактивировать аккаунт</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <app-footer />
    </div>


</template>

<script>
import firebase from 'firebase'
import { sameAs, required, minLength } from 'vuelidate/lib/validators'
import Footer from './Footer'
import moment from 'moment'

export default {
  metaInfo() {
    return {
      title: this.$title('Профиль пользователя')
    }
  },
  data: () => ({
    loading: false,
    passChangeLoad: false,
    userInfoChanged: false,
    oldPass: '',
    newPass: '',
    newPass2: ''
  }),
  components: {
    appFooter: Footer
  },
  validations: {
    oldPass: {required, minLength: minLength(6)},
    newPass: {required, minLength: minLength(6)},
    newPass2: {sameAsPassword: sameAs('newPass')},
  },
  async mounted() {
    if (!this.$store.getters.userInfo) {
      this.loading = true
      await this.$store.dispatch('getUserInfo')
       this.loading = false
    }
  },
  computed: {
    userInfo: {
      get: function() {
        return this.$store.getters.userInfo
      },
      set: function() {
        // console.log(newValue.firstName)
        // console.log(newValue.lastName)
      }
    },
    subscription() {
      if (this.userInfo.subscriptionTill > Date.now()) {
        return '- активна до ' + moment(this.userInfo.subscriptionTill).format('ll')
      } else {
        return 'Нет активных подписок!'
      }
    }
  },
  methods: {
    updateUserInfo() {
      this.loading = true
      console.log(this.$store.getters.userInfo)
      this.$store.dispatch('updateUserInfo', this.$store.getters.userInfo)
      this.loading = false
      this.userInfoChanged = false
    },
    async changePassword() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      var user = firebase.auth().currentUser
      const credential = firebase.auth.EmailAuthProvider.credential(
        user.email, 
        this.oldPass
      );

      try {
        this.passChangeLoad = true
        await user.reauthenticateWithCredential(credential  )
        await user.updatePassword(this.newPass)
        this.$message('Пароль успешно изменен!')
        this.oldPass = ''
        this.newPass = ''
        this.newPass2 = ''
        this.passChangeLoad = false
      } catch (error) {
        this.$error('Что-то пошло не так!')
        this.passChangeLoad = false
      }
    }
  }
}
</script>

<style>

</style>